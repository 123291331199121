/* 电脑分辨率  start*/
/* 在视口宽度大于等于 1025px 时应用的样式 */
@media (min-width: 1025px) {
  .headerMenu {
    display: flex !important;
    margin-left: 18px !important;
  }

  .newsDetailTime {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    margin-right: 58px;
  }

  .newsListBox {
    background: #FFF;
    border-radius: 4px;
    min-height: 600px;
    margin-bottom: 20px;
    padding: 16px 28px;
  }

  .newsDetailBox {
    background: #FFF;
    border-radius: 4px;
    min-height: 600px;
    padding: 44px 77px;
  }

  .shareCouponBoxLimit {
    color: #777777;
    font-size: 12px;
    width: 386px;
    display: inline-block;
  }

  .shareCouponBoxShareBox {
    max-width: 328px;
    margin-left: 67px;
  }

  .goldCoinNotEnough {
    font-size: 12px;
    color: #F92D2D;
    margin-left: 88px;
    margin-top: -12px;
  }

  .cardColBox {
    padding: 100px 0px;
  }

  .buyPackageHeader {
    padding: 25px;
    background: linear-gradient(to right, #DFE7FF, #E6F7FD);
  }

  .buyPackageHeaderTitle {
    color: #222222;
    font-size: 22px !important;
    font-weight: bold;
    text-align: center;
  }

  .buyBtnMoney {
    font-size: 25px;
    font-weight: bold;
    color: #FFF;
  }


  .profileUserNane {
    background: #E9F3FF;
    min-height: 93px;
    text-align: left;
    line-height: 72px;
    margin-bottom: 20px;
  }

  .headerVIPBox {
    background: #F99E6C;
    border-radius: 50px;
    color: #FFF;
    position: absolute;
    bottom: 3px;
    left: 2px;
    height: 16px;
    line-height: 16px;
    width: 36px;
    text-align: center;
    border: #FFF 1px solid;
    font-size: 12px;
  }

  .headerUserImg {
    margin-top: -9px;
    vertical-align: middle;
  }

  .buyBtnStyle {
    min-width: 192px !important;
    height: 56px !important;
  }


  .buyLowerRate {
    font-size: 14px;
    float: right;
    margin-top: -3px;
    margin-left: 5px;
    border-radius: 50px;
    background: #FFFFFF;
    color: #F5890C;
    padding: 0px 7px;
  }

  .ant-radio-button-wrapper {
    font-size: 14px !important;
    min-width: 48px !important;
    text-align: center !important;
  }

  .tabContentBox {
    padding: 20px 40px;
    background: #FFF;
  }

  .cardColBoxImg {
    padding: 40px 0px;
    text-align: right;
  }

  .rootBox {
    padding: 20px 13vw;
    /* padding: 20px; */
    background: #F5F5F5;
  }

  .agentInviteHeaderText {
    font-size: 22px;
    color: #FFFFFF;
    margin: 6px 200px 0px 16px;
  }

  .bannerItemBox {
    padding: 0px 13vw;
    height: 300px;
    position: relative !important;
    overflow: hidden !important;
    cursor: pointer !important;
    background: linear-gradient(to bottom, #31ABF0, #42DCF1);
    display: flex;
  }

  .bannerItemRight {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .bannerItemImg {
    float: right;
    width: 22vw;
    max-width: 300px;
  }

  .bannerItemLeft {
    flex: none;
    max-width: 50vw;
  }

  .bannerItemTitle {
    color: #FFFFFF;
    font-size: 24px !important;
    font-weight: bold;
    /* top: 40% !important; */
    top: 66px !important;
    text-align: left;
    margin-bottom: 16px;
    margin-top: 64px;
  }


  .bannerItemBtn {
    margin-top: 40px;
    text-align: left;
  }

  .bannerBtnBox {
    position: absolute !important;
    z-index: 99999;
    bottom: 50px;
    left: 13vw;
  }

  .banner_btn {
    /* backgroundColor:"#0766F4",color:"#FFF",margin:"0px 10px" */
    background-color: #0766F4 !important;
    color: #FFF !important;
    margin-right: 10px !important;
    padding: 0px 40px !important;
    /* font-size: 16px !important; */
    font-weight: bold !important;
    border: none !important;
    border-radius: 4px !important;
  }

  .agentPagePeople {
    height: 104px;
    position: absolute;
    right: 28px;
    bottom: 0px;
  }

  .headerMenuPhone {
    display: none !important;
  }

  .textTitle {
    font-size: 14px !important;
    color: #222222 !important;
    margin-right: 5px !important;
    /* margin-bottom: 8px !important; */
    min-width: 72px !important;
    display: inline-block !important;
    text-align: right;
  }

  .bodyBox {
    padding: 30px 10vw !important;
  }

  .cardItem {
    width: 220px;
    display: inline-block;
    margin: 10px !important;
    box-shadow: #eef2f7 0px 2px 8px 0px;
  }

  .listLine {
    display: flex;
    align-items: center;
    margin: 10px 0px !important;
  }



  .login_submit {
    width: 255px !important;
    height: 40px !important;
    background: #0766F4 !important;
    border-radius: 2px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-top: 10px !important;
  }

}

/* 电脑分辨率  end*/




/* 手机分辨率  start *************************************************************************************************************************************************************/
/* 视窗宽度小于等于 1024px 时 */
@media (max-width: 1024px) {
  .headerMenu {
    display: none !important;
  }

  .newsDetailTime {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    margin-right: 28px;
  }

  .cardColBox {
    padding: 28px 0px;
  }

  .newsListBox {
    background: #FFF;
    border-radius: 4px;
    min-height: 300px;
    margin-bottom: 20px;
    padding: 16px 10px;
  }

  .newsDetailBox {
    background: #FFF;
    border-radius: 4px;
    min-height: 600px;
    padding: 28px 10px;
  }

  .shareCouponBoxLimit {
    color: #777777;
    font-size: 12px;
    width: 300px;
    display: inline-block;
  }

  .shareCouponBoxShareBox {
    max-width: 328px;
    margin-left: 0px;
  }

  .goldCoinNotEnough {
    font-size: 12px;
    color: #F92D2D;
    margin-top: -12px;
  }

  .tabContentBox {
    padding: 10px;
    background: #FFF;
  }

  .buyPackageHeader {
    padding: 0px;
    background: linear-gradient(to right, #DFE7FF, #E6F7FD);
  }

  .buyPackageHeaderTitle {
    color: #222222;
    font-size: 16px !important;
    font-weight: bold;
    text-align: center;
  }

  .buyBtnMoney {
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
  }

  .buyBtnStyle {
    min-width: 108px !important;
    height: 35px !important;
  }


  .headerUserImg {
    margin-top: 0px;
    vertical-align: middle;
  }


  .headerVIPBox {
    background: #F99E6C;
    border-radius: 50px;
    color: #FFF;
    position: absolute;
    bottom: -7px;
    left: 2px;
    height: 18px;
    line-height: 18px;
    width: 36px;
    text-align: center;
    border: #FFF 1px solid;
  }

  .profileUserNane {
    background: #E9F3FF;
    min-height: 93px;
    text-align: left;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .buyLowerRate {
    font-size: 11px;
    float: right;
    margin-top: -2px;
    margin-left: 5px;
    border-radius: 50px;
    background: #FFFFFF;
    color: #F5890C;
    padding: 0px 7px;
  }

  .ant-radio-button-wrapper {
    font-size: 14px !important;
    min-width: 29px !important;
    text-align: center !important;
  }

  .cardColBoxImg {
    padding: 20px 0px;
    text-align: center;
  }

  .rootBox {
    padding: 20px;
    background: #F5F5F5;
  }

  .headerMenuPhone {
    display: flex !important;
    margin-left: 20px;
  }

  .bannerItemBox {
    padding: 0px 20px;
    height: auto;
    min-height: 168px;
    position: relative !important;
    overflow: hidden !important;
    cursor: pointer !important;
    background: linear-gradient(to bottom, #31ABF0, #42DCF1);
    display: flex;
  }

  .bannerItemRight {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .bannerItemImg {
    float: right;
  }

  .bannerItemLeft {
    flex: none;
    max-width: 80vw;
  }

  .bannerItemTitle {
    color: #FFFFFF;
    font-size: 16px !important;
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
    margin-top: 10px;
  }


  .bannerItemBtn {
    margin: 20px 0px;
    text-align: left;
  }

  .bannerBtnBox {
    position: absolute !important;
    z-index: 99999;
    bottom: 38px;
    left: 27px;
  }

  .banner_btn {
    /* backgroundColor:"#0766F4",color:"#FFF",margin:"0px 10px" */
    background-color: #0766F4 !important;
    color: #FFF !important;
    margin-right: 10px !important;
    padding: 0px 12px !important;
    font-weight: bold !important;
    border: none !important;
    border-radius: 4px !important;
    font-size: 12px !important;
  }

  .agentInviteHeaderText {
    font-size: 17px;
    color: #FFFFFF;
    margin: 6px 80px 0px 16px;
  }

  .agentPagePeople {
    height: 50px;
    position: absolute;
    right: 28px;
    bottom: 0px;
  }

  .textTitle {
    font-size: 14px !important;
    color: #222222 !important;
    margin-right: 5px !important;
    /* margin-bottom: 4px !important; */
    min-width: 72px;
    max-width: 78px;
    display: block !important;
    text-align: right;
  }

  .bodyBox {
    padding: 30px 10px !important;
  }

  .cardItem {
    width: 80vw;
    display: inline-block;
    margin: 10px !important;
    box-shadow: #eef2f7 0px 2px 8px 0px;
  }

  .listLine {
    display: flex;
    align-items: center;
    margin: 10px 0px !important;
  }

  .ant-drawer-body {
    padding: 24px 0px 80px 0px !important;
  }

  /* .login_input {
    border: none !important;
    padding: 10px !important;
    background: #f8f8f8 !important;
    width: 208px !important;
  } */

  .login_boxPhone form {
    margin: 0px 10px 10px 10px !important;
  }


  .login_submit {
    width: 255px !important;
    height: 40px !important;
    background: #0766F4 !important;
    border-radius: 2px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-top: 10px !important;
  }


}

/* 手机分辨率  end */




.cardImgDiv {
  width: 40vw;
}

.cardImg {
  background-size: 100% 100% !important;
  width: 40vw;
  position: relative !important;
}


html,
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box !important;
}


body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  width: 0px !important;
}

.ant-radio-group .ant-radio-button-wrapper {
  /* border-radius: 13px !important; */
  border-radius: 2px !important;
  border-left-width: 1px !important;
}

.tagStyle {
  border-radius: 9px !important;
  height: 23px !important;
  margin: 3px !important;
  border: none !important;
}

.btnBuy {
  background-color: #F5890C !important;
  /* height: 63px !important; */
}

.lineHeight35 {
  line-height: 35px;
}

.lineHeight29 {
  line-height: 29px;
}

.borderRadius13 {
  border-radius: 13px !important;
}

.numberInputStyle {
  width: 100px !important;
  /* height: 32px !important;
  line-height: 30px !important; */
  /* border-radius: 13px !important; */
  border-radius: 2px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.headerMenuPhone {
  color: #0890FF !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

.speedDial {
  cursor: pointer !important;
  bottom: 20px !important;
  right: 15px !important;
  position: fixed !important;

  pointer-events: auto !important;
  color: #fff !important;
}

.MuiFab-primary {
  background-color: #1976d2 !important;
  outline: none !important;
}

.MuiFab-primary:hover {
  text-decoration: none !important;
}

.addCardLabel {
  /* width: 100% !important;
  height: 100% !important; */
  cursor: pointer !important;
}

.inputpaset {
  height: 100% !important;
  border: none !important;
  width: 100% !important;
  color: transparent !important;
  position: absolute !important;
  left: 0 !important;
}

.showTipTop {
  display: none !important;
  position: absolute !important;
  z-index: 999 !important;
  width: 100% !important;
  left: 0 !important;
  margin-top: 10px !important;
}

.showTipBottom {
  display: none !important;
  position: absolute !important;
  z-index: 999 !important;
  bottom: 0 !important;
  width: 100% !important;
  left: 0 !important;
  margin-bottom: 10px !important;
}

.cardAdd:hover .showTipTop,
.cardAdd:hover .showTipBottom {
  display: inline !important;
}

.showImg {
  width: 100px !important;
  height: 100px !important;
}

.btnBoss {
  height: auto !important;
  min-height: 45px !important;
}

.imgDialog {
  cursor: move !important;
}

.dialogTitleImg {
  position: absolute !important;
  width: 600px !important;
  height: 600px !important;
  margin-left: -300px !important;
  margin-top: -300px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  text-align: center !important;
  justify-content: center !important;
}

.downloadBtn {
  /* background: -webkit-linear-gradient(right, #1361ff, #00aeee, #9965f6) !important; */
  background: #0766F4 !important;
  color: #FFF !important;
  margin-bottom: 40px !important;
  border-radius: 4px !important;
  padding: 18px !important;
  padding-bottom: 20px !important;
}

.gongxinbu {
  color: inherit !important;
  margin: 0 10px !important;
}

.footerImg {
  width: 18px !important;
  margin-top: -3px !important;
  margin-right: -5px !important;
}

.addCardIcon {
  position: absolute !important;
  bottom: 50% !important;
  top: 50% !important;
  font-size: 130px !important;
  color: #a8a8a87a !important;
  margin: -65px !important;
  left: 50% !important;
}

.cardAdd {
  width: 100% !important;
  min-height: 300px !important;
  height: 100% !important;
  text-align: center !important;
  cursor: pointer !important;
  position: relative !important;
}

.cardHieght {
  height: 100% !important;
  position: relative !important;
}


body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}

body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  /* background-color: skyblue !important; */
  /* background: linear-gradient(-45deg, rgba(28, 206, 234, 0.82) 0%, rgba(147, 26, 222, 0.83) 100%) !important; */
  /* background: linear-gradient(-45deg, #205edb 0%, #00aeee 100%) !important; */
  background: #adacac;
}

body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
  background: #ededed !important;
  /* border-radius: 10px !important; */
}

.ainiLogo {
  height: 45px !important;
}

.registerLogin {
  position: absolute !important;
  right: 0px !important;
  top: 10px !important;
  z-index: 99999 !important;
  color: #FFF !important;
}

.registerLogin span {
  margin: 8px !important;
  cursor: pointer !important;
}


.navbarStyle {
  /* background-color: #17a2b8  !important;
  background: linear-gradient(-45deg, rgba(147, 26, 222, .83), rgba(28, 206, 234, .82)) !important; */

  background: #FFF !important;
  box-shadow: 0 2px 10px 0 rgba(180, 189, 221, .25) !important;
  z-index: 999 !important;
  position: relative !important;
  padding: 0px !important;

}

.navbarStylePhone {
  padding: 0px !important;
}


input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background: #fff !important;
  background-color: #fff !important;
  background-image: none !important;
  transition: background-color 50000s ease-in-out 0s !important;
}




.menuItemText {
  font-size: 30px !important;
}


.logoText {
  text-align: center !important;
  font-size: 2.5rem !important;
}


.bg-info {
  background-color: rgba(28, 206, 234, 0.82) !important;
  background: linear-gradient(-45deg, rgba(147, 26, 222, 0.83) 0%, rgba(28, 206, 234, 0.82) 100%) !important;
}

.avatar {
  width: 100% !important;
  height: 150px !important;
  border-radius: 0px !important;
  /*left: 50% !important;*/
  /*margin-left: -75px !important;*/
}


.headBtnBox {
  float: right !important;
  margin: 13px 20px 0 0 !important;
}

.headBtn {
  margin: 0px 5px !important;
  height: 26px !important;
  /* line-height: 26px  !important; */
  padding: 0px 18px !important;
  border-radius: 50px !important;
}

.loginStyle {
  /* background-image: url('/img/login_img.png') !important; */
  -moz-background-size: 100% 100% !important;
  background-size: 100% 100% !important;
  width: 800px !important;
  height: 400px !important;
  position: relative !important;
  max-width: 95vw !important;
}

.loginStyle .ant-form-item {
  margin-bottom: 10px !important;
}


.login_box {
  position: absolute !important;
  right: 57px !important;
  top: 51px !important;
}

.login_boxPhone {
  padding-top: 80px !important;
  text-align: center !important;
}

.register_box {
  position: absolute !important;
  right: 49px !important;
  top: 13px !important;
}

.register_boxPhone {
  text-align: center !important;
}


.login_title {
  /* text-align: center !important;
  font-size: 30px !important;
  color: #3576f0 !important;
  font-weight: bold !important;
  margin-bottom: 20px !important; */

  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-bottom: 45px;
}

.login_input_img {
  height: 18px !important;
}

.login_input {
  border-radius: 2px !important;
  border-color: #DDDDDD !important;
}

.login_input input {
  background: #fff !important;
}

.register_xieyi {
  color: #2c4bd5 !important;
  cursor: pointer !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

.input_icon {
  font-size: 17px !important;
  color: #548af4 !important;
}


.ant-message {
  z-index: 9999999 !important;
}

.footbox {
  width: 100% !important;
  text-align: center !important;
  margin: 20px 0px 10px 0px !important;
}

.contentBox {
  text-align: center !important;
  align-items: center !important;
  width: 100% !important;
  margin: 20px 0px !important;
}


.contentRowBase {
  padding: 120px 0px !important;
}


.contentRowBackground {
  padding: 120px 0px !important;
  /* background: #f5f7fa !important; */
  background: #f8f9ff !important;
}

.contentTitle {
  font-size: 32px !important;
  text-align: left !important;
  /* font-weight: bold !important; */
  color: #222222;

}

.logo {
  float: left !important;
  width: 120px !important;
  height: 31px !important;
  /* margin: 16px 24px 16px 0 !important; */
}

.contentText {
  color: #666666;
  font-size: 14px !important;
  /* text-align: left !important; */
  text-align: justify !important;
  margin-right: 19px !important;
  /* margin-bottom: 30px !important; */
  margin-top: 22px !important;
  /* white-space: pre-wrap; ===>让div 解析换行\n为换行 */
  white-space: pre-wrap;
  line-height: 26px;
}

.user_head {
  cursor: pointer !important;
  border-radius: 50px;
}

.profileHead {
  margin: 26px !important;
}

.profileHead_img {
  border-radius: 20px !important;
}

.profile_user_box {
  color: rgba(0, 0, 0, 0.87) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  /* display: flex !important; */
  min-width: 0px !important;
  overflow-wrap: break-word !important;
  background-color: rgb(255, 255, 255) !important;
  background-clip: border-box !important;
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.75rem !important;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem !important;
  overflow: visible !important;
  position: relative !important;
  margin-top: -64px !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
  padding: 16px !important;
  min-height: 115px !important;
}

.profile_userTitle_box {
  display: inline-block !important;
  margin: 0px 30px !important;
}

.profile_user_member {
  position: absolute !important;
  right: 100px !important;
}

.profile_user_msg {
  font-size: 28px !important;
  line-height: 1.375 !important;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 600 !important;
}

.profile_user_msg_sub {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 20px !important;
  line-height: 1.5 !important;
  color: rgb(123, 128, 154) !important;
  font-weight: 400 !important;
}

.forgetPassword {
  float: right !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  margin: 0px 8px !important;
}


.btnDrawerBuy {
  position: absolute !important;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.btnDrawerBuyComputer {
  margin-left: 131px;
}

.textTitlePrice {
  font-size: 18px !important;
  margin-right: 5px !important;
  min-width: 110px !important;
  display: inline-block !important;
}

.surePayBtn {
  margin-left: 50px !important;
}

.textdetail {
  font-size: 16px !important;
  margin: 0px 5px !important;
}

.textPrice {
  font-size: 26px !important;
  color: #f00 !important;
  text-align: center;
}

.textOriginalPrice {
  text-decoration: line-through;
  font-size: 10px;
  float: right;
  margin-top: -3px;
  margin-left: 5px;
}

.textredtip {
  font-size: 12px !important;
  color: #F00 !important;
  line-height: 30px !important;
  margin: 0px 10px !important;
}

.smallIcon {
  width: 20px !important;
  height: 20px !important;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 5px;
}

.loginModal .ant-modal-content {
  padding: 0px !important;
}

.priceTime .ant-statistic {
  display: inline-block !important;
}

.priceTime .ant-statistic .ant-statistic-content {
  font-size: 14px !important;
}

.imageCardImg {
  border-radius: 1rem !important;
}

.textCenter {
  text-align: center !important;
  top: 10% !important;
  position: sticky !important;
}

.downloadTitle {
  font-size: 24px !important;
  margin: 38px 0px !important;
}

.boxShadowCard {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  box-shadow: rgba(99, 99, 99, 0.1) 0px 8px 17px 0px;
  padding: 30px;
  margin: 10px;
  /* max-width: 355px; */
  min-height: 380px;
  background: #FFFFFF;
}

.helpTitle {
  font-size: 20px !important;
  margin-bottom: 20px !important;
}

.helpTitleSub {
  font-size: 16px !important;
  margin-left: 20px !important;
}

.helpImg {
  max-height: 550px !important;
}

.helpNotes {
  color: #b9babd !important;
  margin: 10px 0px !important;
}

.agreementHeader {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.agreementTitle {
  font-weight: bold !important;
}

.agreementcontent {
  text-indent: 2em !important;
  font-size: 14px !important;
  padding: 0 0 5px 0 !important;
  word-break: break-all !important;
  line-height: 24px !important;
}

.ant-menu-title-content {
  width: 100% !important;
  display: grid !important;
}

.ant-float-btn-group-circle-shadow {
  bottom: 88px !important;
}

.cardBtn {
  width: 100%;
  /* background: linear-gradient(to right, #F07362, #EF5353) !important; */
  background: #F92D2D !important;
  border-radius: 2px !important;
}

.cardBtn1 {
  width: 50%;
  background: #F92D2D !important;
  border-radius: 2px !important;
}

.cardBtn2 {
  width: 50%;
  background: #0075FF !important;
  border-radius: 2px !important;
}

.messageTitle {
  width: 68px;
  display: inline-block;
  margin-right: 10px;
}

.messageText {}

.messageBox {
  margin: 10px 0px;
}

.ant-radio-button-wrapper {
  margin: 3px !important;
}

.sys_svg_icon path {
  fill: currentColor;
}

.settingDivMargin {
  margin: 10px 0px;
}

.colCard {
  margin: 10px !important;
  min-width: 300px !important;
  min-height: 140px !important;
  box-shadow: #eef2f7 0px 2px 8px 0px !important;
  border: 1px solid #f1f1f1 !important;
}

.colCardSvg {
  width: 50px;
  height: 50px;
  float: right;
}

svg path {
  fill: currentColor;
}

.colCardTitle {
  /* color: rgb(136, 152, 170) !important;
  font-size: .8125rem;
  font-weight: 600 !important; */
  color: #222222 !important;
  font-size: 16px;
  font-weight: 600 !important;
}

.colCardContent {
  font-size: 1.25rem;
  color: #32325d;
  font-weight: 600 !important;
}

.colCardFooter {
  margin-top: 1rem;
  color: rgb(136, 152, 170);
  font-size: 0.875rem;
}

.colCardActionBtn {
  color: #1677ff !important;
  outline: none;
  cursor: pointer;
}


.itemMain {
  /* padding-right: 60px !important; */
  margin: 0px;
  min-height: 90vh;
  background: #FFF;
}

.couponCard {
  /* text-align: center; */
  display: inline-block;
  margin: 10px 10px 10px 0px;
  border-radius: 7px;


  /* 调好的上下圆圈缺口 */
  /* width: 300px;
  height: 150px;
  background: linear-gradient(0.25turn, #fe8864, #FE5151),
    radial-gradient(circle at 11px 4px, transparent 6px, #FE5151 6px) top left,
    radial-gradient(circle at 10px 124px, transparent 6px, #FE5151 6px) bottom left,
    linear-gradient(0.25turn, #FE5151, #fe434c);
  background-size: 240px 150px, 20px 50px, 20px 150px, 40px 150px;
  background-repeat: no-repeat;
  background-position: 0px 0px, 240px -4px, 240px 26px, 260px 0px; */


  width: 300px;
  height: 150px;
  background: radial-gradient(circle at 0 75px, transparent 8px, #fc3868 1px),
    linear-gradient(0.25turn, #fc3868, #fc3868) top left,
    radial-gradient(circle at 10px 4px, transparent 8px, #fc3868 1px) top left,
    radial-gradient(circle at 10px 130px, transparent 8px, #fc3868 1px) bottom left,
    linear-gradient(to right, #fc3868 0%, #ee2c2d 40%, #ee2c2d 100%),
    radial-gradient(circle at 10px 75px, transparent 8px, #ee2c2d 1px);
  background-size: 17px 150px, 40px 150px, 20px 75px, 20px 150px, 220px 150px, 10px 150px;
  background-repeat: no-repeat;
  background-position: 0px 0px, 10px 0px, 50px -4px, 50px 20px, 70px 0px, 290px 0px;

}

.couponCardTitle {
  font-size: 36px;
  color: #FFF;
}

.couponCardLeft {
  width: 61px;
  height: 127px;
  text-align: center;
  display: inline-block;
  writing-mode: vertical-rl;
  padding-right: 17px;
  font-weight: bold;
  color: #FFF;
  margin-top: 11px;
  border-right: #dadada dashed 2px !important;
}

.couponCardContent {
  display: inline-block;
  text-align: center;
  height: 150px;
  width: 230px;
  float: right;
  padding: 10px;
}


/* 另一个版本的 优惠券 */


.couponCardNew {
  text-align: center;
  display: inline-block;
  margin: 10px 10px 10px 0px;
  border-radius: 7px;
  width: 300px;
  height: 118px;
  background: radial-gradient(circle at 0 59px, transparent 8px, #fc3868 1px),
    linear-gradient(0.25turn, #fc3868, #fc3868) top left,
    radial-gradient(circle at 10px 4px, transparent 8px, #fc3868 1px) top left,
    radial-gradient(circle at 10px 98px, transparent 8px, #fc3868 1px) bottom left,
    linear-gradient(to right, #fc3868 0%, #ee2c2d 40%, #ee2c2d 100%),
    radial-gradient(circle at 10px 59px, transparent 8px, #ee2c2d 1px);
  background-size: 17px 118px, 40px 118px, 20px 59px, 20px 118px, 220px 118px, 10px 118px;
  background-repeat: no-repeat;
  background-position: 0px 0px, 10px 0px, 50px -4px, 50px 20px, 70px 0px, 290px 0px;

}


.couponCardTitleNew {
  font-size: 36px;
  color: #FFF;
}

.couponCardLeftNew {
  width: 52px;
  height: 87px;
  text-align: center;
  display: inline-block;
  writing-mode: vertical-rl;
  padding-right: 17px;
  font-weight: bold;
  color: #FFF;
  margin-top: 15px;
  border-right: #dadada dashed 2px !important;
}

.couponCardContentNew {
  display: inline-block;
  text-align: center;
  height: 118px;
  width: 230px;
  float: right;
  padding: 10px;
  padding-top: 20px;
}

.goldCoinBox {
  display: flex;
  background: linear-gradient(90deg, #f57701 10%, #fcaf19 57%);
  margin: 10px 10px 10px 0px;
  width: 300px;
  height: 118px;
  border-radius: 7px;
}

.goldCoinText {
  width: 62px;
  height: 87px;
  padding-right: 17px;
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
  margin-top: 15px;
  writing-mode: vertical-rl;
  text-align: center;
  border-right: #dadada dashed 2px !important;
}

.goldCoinNum {
  flex-grow: 1;
  color: #FFF;
  font-size: 36px;
  line-height: 118px;
}


.ant-popover-content {
  max-width: 338px;
}

.getConponTab {
  display: flex;
  justify-content: center;
}

.addLimitCouponmoney {
  margin: 0px 20px;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;

}

.popconfirmStyle {
  cursor: pointer;
}

.descriptionsStyle {
  width: 207px;
  text-align: center;
  display: inline-block;
}

.profileCard {
  min-width: 300px;
  margin: 10px 0px;

}

.profileCard .ant-card-body {
  box-shadow: #eef2f7 0px 2px 8px 0px;
}

.qrCodeLoginBox {
  text-align: center;
  margin-bottom: 50px;
}

.qrCodeLoginTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.empowerTitle {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

/* 设计之后的样式 20240509 */
.headerbox {
  display: inline-block;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.headerLeft {
  float: left;
  height: 100%;
  display: flex;
}

.headerRight {
  float: right;
  height: 100%;
  display: inline-block;
  /* padding-right: 43px; */
  padding-right: 23px;
}

.ant-btn{
  border-radius: 4px !important;
}

.headerRight .ant-btn {
  border: none !important;
  border-radius: 2px !important;
  font-size: 12px;
  padding: 0px 10px;
}

.headerLogo {
  display: inline-block;
  height: 100%;
  padding: 0px 32px;
  background: #0766f4;
}

.headerBtn {
  background: #0766f4 !important;
  color: #FFF !important;
  margin-left: 10px !important;
}

.headerBtnRed {
  background: #f92d2d !important;
  color: #FFF !important;
  /* margin-left: 10px !important; */
  /* margin-right: 56px !important; */
}

.headerHeadBox {
  /* margin-left: 95px; */
  margin-left: 23px;
  display: inline-block;
  cursor: pointer;
}

.headerUserName {
  font-size: 16px;
  color: #555555;
  margin-right: 5px;
  max-width: 100px;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerHeadImgBox {
  display: inline-block;
  position: relative;
  margin-right: 0px;
}

.cardBackground0 {
  background: #FFFFFF;
}

.cardBackground {
  background: #F8FBFF;
}

.FunctionCardTitle {
  display: inline-block;
  /* text-align: center; */
  margin-left: 24px;
  color: #222222;
  font-size: 20px;
  font-weight: bold;
}

.FunctionCardImg {
  display: inline-block;
  float: right;
  margin-right: 23px;
}

.FunctionCardText {
  display: inline-block;
  width: 50%;
  text-align: center;
  border: 1px solid #F2F2F2 !important;
  color: #666666;
  font-size: 14px;
}

.FunctionCardBox {
  min-width: 235px !important;
  min-height: 140px !important;
  box-shadow: #eef2f7 0px 2px 8px 0px !important;
  border: 1px solid #f1f1f1 !important;
  background: #FFF;
}

.FunctionCardTop {
  height: 100px;
  line-height: 100px;
}

.FunctionCardBottom {
  height: 40px;
  line-height: 40px;
}

/* 按钮上添加这个buyShopType样式 */
.buyShopType {
  position: relative;
  padding: 0px 30px !important;
}

/* 对应的按钮 height: 40px; */
label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.buyShopType::before {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="12" height="12" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-4c912b1c=""><path d="M77.248 415.04a64 64 0 0190.496 0l226.304 226.304L846.528 188.8a64 64 0 1190.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 010-90.496z" fill="white"></path></svg>');
  position: absolute;
  right: 2px !important;
  top: -10px !important;
  left: auto;
  width: 12px !important;
  height: 0px !important;
  z-index: 999 !important;
  padding: 0px !important;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.buyShopType::after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0;
  height: 0;
  border-radius: 2px;
  border-style: solid;
  border-width: 0 35px 25px 10px;
  border-color: transparent #0766F4 transparent transparent;
}

label.ant-radio-button-wrapper.buyShopType {
  background: #DFEAFC !important;
  border: none !important;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.buyShopType {
  background: #ffffff !important;
  border: #0766F4 solid 1px !important
}

label.ant-radio-button-wrapper.buyShopType {
  border-radius: 6px !important;
  padding: 0px 30px;
  font-size: 14px;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background: #e6f0fe !important;
  font-size: 15px !important;
  font-weight: bold;
}

.newsList .ant-tabs-tab.ant-tabs-tab-active,
#OtherJoinPage .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff !important;
  font-size: 18px !important;
  font-weight: bold;
}

.newsList .ant-tabs-nav-list{
  margin-left: 0px !important;
}

.backgroundWhite .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff !important;
  font-size: 18px !important;
  font-weight: bold;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #222222 !important;
}


.newsList .ant-tabs .ant-tabs-tab-btn{
  font-size: 18px;
  font-weight:300;
}

.newsList .ant-tabs-tab-active .ant-tabs-tab-btn{
  font-weight:bold !important;
}


.ant-tabs-tab {
  background: #FFF !important;
  border: none !important;
}

.newsList .ant-tabs-tab{
  padding-bottom: 15px !important;
}

.ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: opacity 0.3s;
  margin-left: 20px;
}

.ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color: #777777;
  font-size: 15px;
  font-weight: bold;
}

.ant-tabs-nav-wrap {
  border-bottom: #E0E0E0 solid 1px !important;
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody>tr>td {
  word-wrap: break-word;
  word-break: break-all;
}

/* .ant-table-cell{
  padding: 5px !important;
} */


.rootContent {
  background: #FFFFFF;
  padding: 20px 0px;
  min-height: 78vh;
}

.resourceContent {
  white-space: pre-wrap;
  margin: 12px 12px 20px 12px;
  font-size: 12px;
  color: #999999;
  min-height: 178px;
}

.resourceContentBtn {
  padding: 0px 8px !important;
  height: 20px !important;
  line-height: 20px !important;
  font-size: 12px !important;
}

.couponTextBase {
  color: #666666;
}

.couponTextBlack {
  color: #222222;
  font-weight: bold;
}

.couponTextBlue {
  color: #0766F4;
  font-weight: bold;
}

.couponTextRed {
  color: #EC1515;
  font-weight: bold;
  font-size: 18px;
  text-decoration: line-through;
}

.buyBtnStyleRed {
  background: #F92D2D !important;
}

.buyLowerRateRed {
  color: #F92D2D !important;
}

.freeGetPriceBtn {
  font-size: 16px !important;
  background: #0766F4 !important;

}

.buyPackageHeaderText {
  color: #F8483E;
  font-size: 14px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.buyPackageHeaderBtn {
  background: #FC4949 !important;
  color: #FFF !important;
  font-size: 14px;
}

.userPageBox {
  background: linear-gradient(to right bottom, #dce7fb, #b4dffd);
  padding: 20px;
  /* min-height: 95vh; */
}

.statisticsPageBox {
  /* background: linear-gradient(to right bottom, #dce7fb, #b4dffd); */
  background: #e8f8ff;
  padding: 20px;
}

.userpageIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
}

.userPageCardBox {
  background: #FFF;
  border-radius: 4px;
}

.userPageCard {
  position: relative;
  min-height: 143px !important;
  border-radius: 4px;
  /* background: #FFF; */
  background: url('../public/img/pro/agentCardBackgroud.png') no-repeat;
  background-position: bottom;
  background-size: 100% auto;

}

.userPageCardCol {
  min-height: 143px !important;
  border-radius: 4px;
  text-align: center;
}

.userPageCardColBox {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  color: #777777;
  margin-top: 14px;
}

.userPageCardColNumBox {
  color: #222222;
  margin-top: 15px;
}

.userPageCardColNum {
  font-size: 28px;
  font-weight: bold;
  color: #222222;
}

.profileUserNaneCard {
  padding-left: 38px !important;
  margin: 10px 0px;
}

.headerUserNameTitle {
  color: #777777;
  font-size: 16px;
}

.headerUserNameText {
  color: #222222;
  font-size: 16px;
}


.agentInviteHeader {
  height: 126px;
  background: linear-gradient(to right, #ff927e, #ff6d6b);
  position: relative;
  border-radius: 4px;
}

.agentInviteHeaderLogo {
  font-size: 12px;
  color: #FFFFFF;
  padding: 16px;
}


.agentInviteHeaderLogoImg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 6px;
}

.agentInviteTextBox {
  background: #F8F8F8;
  min-height: 376px;
  border-radius: 4px;
  padding: 25px;
}

.agentIcon {
  width: 64px;
  height: 64px;
  margin-bottom: 21px;
}

.inviteTitle {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
  margin-bottom: 12px;
}

.inviteText {
  font-size: 12px;
  color: #666666;
}

.agentInviteBtnBox {
  text-align: center;
  margin-top: 50px;
}

.exchangeCouponHeader {
  min-height: 80px;
  line-height: 80px;
  background: #e9f3ff;
  padding: 5px;
  margin-bottom: 20px;
}

.exchangeCouponMy {
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  line-height: 16px;
  cursor: pointer;
}

.exchangeCouponDetailText {
  font-size: 12px;
  font-weight: bold;
  color: #EE4949;
  line-height: 12px;
  cursor: pointer;
}

.exchangeCouponBuyText {
  color: #777777;
  font-size: 12px;
  margin-left: 10px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.menu_way {
  height: 25px;
  line-height: 25px;
  background: #FFF;
  margin: 10px 0px;
  padding: 0px 22px;
  color: #b4b4b4;
}

.exchangePagemodelDetail {
  color: #777777;
  font-size: 12px;
  margin-bottom: 18px;
}

.exchangePageModelContent {
  padding: 30px;
}

.ant-modal-content {
  border-radius: 4px !important;
}

.ant-modal-title {
  font-size: 20px !important;
  color: #000000 !important;
  /* font-weight: bold !important; */
}

.modelTitle {
  color: #000000;
  font-size: 14px;
  margin: 10px 16px 10px 0px;
}

.modelSelect {
  width: 240px !important;
  height: 40px !important;
  border-radius: 2px !important;
  border: #DDDDDD solid 1px !important;
  margin-bottom: 30px !important;
}

.modelSelect .ant-select-selector {
  border: none !important;
}

.modelBtn {
  border-radius: 2px !important;
  margin-right: 19px;
  border: #0766F4 solid 1px !important;
  color: #0766F4 !important;
  font-size: 14px !important;
  width: 105px;
  height: 40px;
  line-height: 40px;
}

.modelBtnSure {
  color: #FFF !important;
  background: #0766F4 !important;
}

.exchangeBtn {
  color: #0766F4 !important;
  font-size: 14px !important;
  border: #0766f4 solid 1px !important;
  border-radius: 2px !important;
}

.exchangeBtnSmall {
  color: #0766F4 !important;
  font-size: 12px !important;
  border: #0766f4 solid 1px !important;
  border-radius: 2px !important;
}

.menuIconClass {
  vertical-align: middle
}

.couponStatusRed {
  color: #EF2F2F;
  font-size: 12px;
  font-weight: bold;
}

.couponStatusblue {
  color: #0766F4;
  font-size: 12px;
  font-weight: bold;
}

.couponStatusgreen {
  color: #2BBA31;
  font-size: 12px;
  font-weight: bold;
}

.couponStatusgrey {
  color: #999999;
  font-size: 12px;
  font-weight: bold;
}

.agentExchangeModelDetail {
  font-size: 12px;
  color: #777777;
  margin-top: 15px;
}

.shareTitleText {
  font-size: 14px;
  color: #222222;
}

.shareCouponBox {
  align-items: center;
  display: inline-block;
}

.getCouponBox {
  min-height: 100px;
  line-height: 100px;
  text-align: center;
}

.recommendBox {
  /* min-height: 200px; */
  padding: 36px 0px;
  align-items: center;
  display: grid;
}

.ant-modal-body {
  margin-top: 38px;
}

.loginModalBoxRoot {
  background: linear-gradient(90deg, #2081f7 10%, rgb(26, 197, 253) 57%);
  display: flex;
  padding: 40px;
  align-items: center;
  border-radius: 3px;
}

.loginModalBoxRootPhone {
  background: linear-gradient(180deg, #2081f7 10%, rgb(26, 197, 253) 57%);
  display: flex;
  padding: 40px 10px;
  align-items: center;
  border-radius: 3px;
  display: inline-block;
}

.loginModalTitle {
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 30px;
}

.loginModalTitle1 {
  font-size: 26px;
  color: #FFF;
}

.loginModalTitle1Phone {
  font-size: 16px;
  color: #FFF;
  display: flex;
}

.loginDialogModal {
  margin-top: -66px !important;
}

.loginModalBox {
  display: inline-block;
}

.loginModalInputBox {
  position: relative;
  display: inline-block;
  width: 380px;
  height: 380px;
  background: #FFF;
  border-radius: 3px;
  padding: 30px;
}

.loginModalInputBoxPhone {
  position: relative;
  /* width: 380px; */
  min-height: 380px;
  background: #FFF;
  border-radius: 3px;
  padding: 15px;
  padding-bottom: 50px;
}

.loginModalMapBox {
  background-size: 100% 100%;
  width: 700px;
  height: 300px;
}


.loginModalMapBoxPhone {
  background-size: 100% 100%;
  width: auto;
  height: 300px;
  margin-top: 28px;
}

.changeLoginWay {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 44px;
  cursor: pointer;
}


.registerDialog {}

.registerDialog .ant-form-item {
  margin-bottom: 10px !important;
}

.loginIconText {
  font-size: 16px;
  color: #FFF;
  margin-top: 15px;
}

.loginIconTextPhone {
  font-size: 14px;
  color: #FFF;
  margin-top: 15px;
}

.loginIconBox {
  width: 50%;
  margin-bottom: 32px;
}

.login_icon_people {
  width: 130px;
  vertical-align: middle;
  margin-top: -60px;
  margin-left: 30px;
}

.login_icon_peoplePhone {
  width: 99px;
  vertical-align: middle;
  margin-top: -48px;
}

.loginTextBottom {
  font-size: 16px;
  color: #FFF;
  background: #0766F4;
  border-radius: 40px;
  display: inline-block;
  padding: 5px 38px;
  font-weight: 500;
}


.loginTextBottomPhone {
  font-size: 14px;
  color: #FFF;
  background: #0766F4;
  border-radius: 40px;
  display: inline-block;
  padding: 5px 38px;
  font-weight: 500;
}

.agreementBox {
  position: absolute;
  bottom: 10px;
}

.firstDayModalTitle {
  font-size: 32px;
  font-weight: 500;
  color: #222222;
  text-align: center;
}

.firstDayModalDetail {
  color: #F92D2D;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}

.receiveCouponDetail {
  color: #F92D2D;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 38px;
}


.shareCouponBoxTitle {
  color: #222222;
  font-size: 14px;
  /* margin: 20px 0px; */
  min-width: 88px;
}

.shareCouponBoxSelect {
  width: 300px !important;
  margin-bottom: 0px !important;
}

.no_coupon {
  min-width: 300px;
  min-height: 118px;
  background: #F8F8F8;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-style: dashed;
  border-width: 1px;
  border-color: #D2D2D2;
}

.no_coupon_text {
  font-size: 14px;
  color: #999999;
}

.cashoutCheckTotal {
  height: 80px;
  line-height: 80px;
}

.companyInfoBox {
  margin: 10px 0px;
}

.companyInfoTitle {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 28px 10px 0px;
}

.invoiceBox {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 20px;
}

.invoiceBox .ant-upload-list-item-container,
.invoiceBox .ant-upload-select {
  width: 60px !important;
  height: 60px !important;
}

.bannerBtnBoxP {
  position: relative !important;
}

.apiBox {
  margin: 20px 0px;
  line-height: 26px;
}

.apiTitle {
  font-weight: bold;
  margin: 5px 10px;
}

.apiTextarea {
  width: 100%;
  min-height: 400px;
  resize: none;
  background: #f9fafc;
  border: 1px solid #e9eef6;
}

.openShopBox {
  background: #FFF;
  padding: 24px;
  border-radius: 4px;
  padding-bottom: 422px;
}

.openShopCard {
  min-width: 100px;
  min-height: 200px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: #eef2f7 0px 2px 8px 0px !important;

}

.openShopCardHeader {
  /* background: linear-gradient(to right, #EE4D2D, #EE7E2D); */
  height: 120px;
  line-height: 120px;
  padding: 0px 30px;
}

.openShopNow {
  background: #ffffff00 !important;
  color: #FFF !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border: 1px solid #FFFFFF !important;
  float: right;
  margin-top: 45px;
}

.openShopTitle {
  font-size: 18px;
  font-weight: 700;
  color: #222222;
  margin-top: 29px;
}

.openShopNumber {
  background: #bbbbbb;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  border-radius: 2px;
  margin-right: 8px;
}

.linkA {
  color: #222222;
  font-size: 14px;
  text-decoration: underline !important;
}

.openShopFooter {
  height: 102px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openShopFooterBtn {
  color: #0765F4 !important;
  border-radius: 4px !important;
  border: 1px solid #0765F4 !important;
  font-size: 14px !important;
  margin: 5px;
}

/* clamp(minimum, value, maximum) */
.newsDetailTitle {
  font-size: clamp(18px, 1.2vw, 24px);
  font-weight: 700;
  color: #222222;
  margin-bottom: 28px;
}

.newsDetailTag {
  display: inline-block;
  background: #0766F4B2;
  padding: 1px 8px;
  border-radius: 4px;
  margin-right: 9px;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
}

.newsDetailContent {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #666666 !important;
  text-align: justify;
}

.newsListTitle {
  font-size: 20px;
  font-weight: 400;
  color: #222222;
}

.scrollableList .ant-list-item {
  border-bottom: none !important;
  display: block !important;
}

.scrollableList::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}

.scrollableList::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  /* background-color: skyblue !important; */
  /* background: linear-gradient(-45deg, rgba(28, 206, 234, 0.82) 0%, rgba(147, 26, 222, 0.83) 100%) !important; */
  /* background: linear-gradient(-45deg, #205edb 0%, #00aeee 100%) !important; */
  /* background: #adacac; */
  background: #adacac;
}

.scrollableList::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
  /* background: #ededed !important; */
  background: #ededed !important;
  /* border-radius: 10px !important; */
}

/* 隐藏滚动条 */
/* .scrollableList::-webkit-scrollbar {
  display: none;
}

.scrollableList {
  overflow-y: scroll;
  scrollbar-width: none; 
}
 */

.newsDelete {
  position: absolute;
  top: 0px;
  left: 12px;
}

.WeChatGroupBox {
  display: flex;
  align-items: center;
  /* 分别靠两边*/
  justify-content: space-between;
  margin-bottom: 10px;
}

.WeChatGroupName {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  margin-left: 12px;
}

.WeChatGroupBtn {
  color: #0766F4 !important;
  border: 1px solid #0766F4 !important;
}

.openMenuBtn {
  font-size: 12px !important;
  padding: 2px 4px !important;
  height: 20px !important;
  line-height: 13px !important;
  margin: 0px 3px !important;
  border-radius: 4px !important;
}

.openMenuBtnActive {
  background-color: rgb(145 202 247) !important;
  color: #fff !important;
}

.openMenuMore {
  color: #428bca;
  font-size: 12px;
}

.openMenuBox {
  margin-bottom: 8px !important;
}

.openMenuTitle {
  font-size: 14px;
  line-height: 24px;
}

.table_icon_setting_box {
  position: relative;
}

.table_icon_setting {
  z-index: 99;
  position: absolute;
  right: 10px;
  top: 11px;
}

.table_setting_text {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  cursor: default !important;
}

.table_setting_text_item {
  font-size: 15px;
  font-family: Inter, Arial, Helvetica, sans-serif;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  margin-left: 9px;
  margin-right: 20px;
}

.table_setting {
  overflow-y: auto;
  max-height: 400px;
  background: #FFF !important;
}

.table_setting .ant-dropdown-menu-item {
  background: #FFF !important;
}

.table_setting_back {
  color: #005bff;
  background: #6183a21a;
  float: right;
  font-size: 17px;
  padding: 0px 4px;
  border-radius: 4px;
  cursor: pointer;
}

.ozonTrendpageTitle {
  font-size: 18px;
  font-weight: bold;
}

.tableInfoIcon {
  color: #bfbfbf;
  margin-bottom: -1px;
  margin-left: 2px;
}

.ant-notification {
  top: 50px !important;
}

.salesPromotionPage {}

.salesPromotionPage .ant-table-thead .ant-table-cell {
  background: #f5f9ff !important;
  border-bottom: 1px solid #c5dcfd !important;
}

.salesPromotionPage .ant-table-tbody .ant-table-cell {
  border-bottom: 1px solid #c5dcfd !important;
}

.salesPromotionPage .ant-table-content {
  border-radius: 0px !important;
  border-top: 1px solid #c5dcfd !important;
  border-left: 1px solid #c5dcfd !important;
  border-right: 1px solid #c5dcfd !important;
}

.giftBox {
  background: linear-gradient(to bottom right, #d9f9fe, #d9e7ff);
  padding: 23px;
}

.giftTitle {
  font-size: 28px;
  font-weight: bold;
  color: #222222;
}

.giftSubtitle {
  font-size: 12px;
  color: #555555;
}

.giftSubtitleNumber {
  font-size: 22px;
  color: #F42B2B;
  font-weight: bold;
}


.giftContent {
  position: relative;
  margin-top: 27px;
  margin-bottom: 16px;
  padding: 10px;
  border: 1px solid #cde0fd !important;
  background: #FFFFFF;
  color: #222222;
  font-size: 14px;
  line-height: 28px;
}

.giftContentIcon {
  position: absolute;
  top: -15px;
  right: -14px;
}

.giftMoney {
  font-size: 16px;
  font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
}

.giftUserHeaderBox {
  margin: 21px 0px 41px 0px;
  padding: 10px;
  background: #f5f9ff;
  border: 1px solid #c5dcfd !important;
}

.giftUserTitle {
  text-align: center;
  margin: 30px 0px 60px 0px;
  font-size: 24px;
  font-weight: bold;
  color: #0766F4;
}

.giftUserInvitation {
  text-align: center;
  margin: 30px;
}

.giftUserName {
  text-align: center;
  color: #555555;
  font-size: 12px;
  margin: 8px 0px 10px;
}

.giftHistory {
  font-size: 16px;
  color: #222222;
}

.giftDetail {
  color: #0766F4;
  text-decoration: underline;
  cursor: pointer;
}

.otherJoinPageTitle {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0px 48px;
}

.otherDetailBox {
  background: #FFF;
  padding: 30px;
  border-radius: 4px;
  margin: 10px 0px;
}

.serviceName {
  font-size: 26px;
  font-weight: bold;
  margin: 6px 0px 22px;
}

.otherDetailInfoTitle {
  font-size: 26px;
  /* font-weight: bold; */
  margin: 0px 0px 20px 0px;
}

.otherDetailText {
  font-size: 14px;
  color: #222222;
  margin-left: 7px;
}

.otherDetailTitle {
  margin-right: 10px;
  font-size: 14px;
  min-width: 62px;
  color:#777777;
  
}

#OtherDetailPage .slick-slide {
  display: block;
  text-align: center;
}

#OtherDetailPage .slick-arrow {
  color: #000000;
}

.otherDetailStatus {
  position: absolute;
  top: 13px;
}

.otherItemBox {
  /* box-shadow: #d6e3f1 0px 2px 8px 0px; */
  box-shadow: rgba(99, 99, 99, 0.1) 0px 4px 8px 0px;
  border: #f2f2f2 solid 1px;
  padding: 20px;
}

.serviceListTitle {
  font-size: 18px;
  font-weight: bold;
}


#cityBox .css-dev-only-do-not-override-161f05s.ant-flex-gap-middle {
  gap: 0px;
}

#OtherBusinessPage .ant-checkbox {
  display: none;
}

#OtherBusinessPage .ant-checkbox-wrapper {
  background: #f5f5f5;
  color: rgb(102, 102, 102);
  border-radius: 50px;
  margin: 5px;
  padding: 2px;
}

#OtherBusinessPage .ant-checkbox-wrapper-checked {
  background: #0766f4;
  color: #FFFFFF;
  border-radius: 50px;
}

.homeProductInfoTitle {
  font-size: 32px;
  font-weight: bold;
  padding: 40px 20px 20px 20px;
  text-align: center;
  background: #FFFFFF;
  margin-top: 20px;
  color: #222222;
}

.forwarderSelectTitle {
  font-size: 14px;
  color: #666666;
  width: 92px;
  line-height: 32px;
}

#headerBox .ant-menu-sub {
  border-radius: 2px !important;
  font-size: 14px;
  margin-top: -7px;
}

#headerBox .ant-menu-sub li {
  border-radius: 2px !important;
}

#headerBox .ant-menu-title-content span,
#headerBox .ant-menu-title-content {
  color: #555555;
}

/* #headerBox .ant-menu-title-content span:hover{
  color:#222222 !important;
  font-weight: bold !important;
} */


.ant-layout-sider {
  background: #FFFFFF !important;
}


#headerBox .ant-menu-item-active::after,
#headerBox .ant-menu-submenu-active::after,
#headerBox .ant-menu-item:hover::after,
#headerBox .ant-menu-submenu-selected::after,
#headerBox .ant-menu-item-selected::after {
  border-bottom-width: 4px !important;
  border-bottom-color: #1677ff !important;
  border-radius: 2px !important;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup.ant-dropdown-menu .ant-dropdown-menu,
.ant-dropdown.ant-dropdown-placement-bottomRight .ant-dropdown-menu{
  border-radius: 2px !important;
}

.ant-dropdown-menu-submenu,.ant-dropdown-menu-item{
  padding: 5px 0px !important;
}

.ant-dropdown-menu-item{
  padding:5px 12px !important;
}

.ant-dropdown-menu-title-content,.ant-dropdown-menu-submenu-title{
  color: #555555 !important;
}

.ant-dropdown-menu-sub .ant-dropdown-menu-title-content{
  padding: 0px 20px;
}

.ant-empty{
  margin: 60px 0px !important;
}

.ant-empty .ant-empty-image{
  height: 72px !important;
  margin-bottom: 8px !important;
  opacity: 0.6 !important;
}

.gift_flot{
  position: fixed;
  right: 0px;
  top:50%;
  /* box-shadow: rgba(99, 99, 99, 0.3) -2px 8px 20px 0px; */
}











































/* ----------------- banner--------------------- */
.banner-user-elem {
  text-align: center !important;
  color: #fff !important;
  position: relative !important;
  overflow: hidden !important;
  cursor: pointer !important;
  /* background: linear-gradient(to bottom, #31ABF0, #42DCF1); */

  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 图片将覆盖整个元素，可能被裁剪 */
  /* 或者使用 object-fit: contain; 保持宽高比 */
}

.banner-user-elem .banner-user-title {
  font-size: 24px !important;
  font-weight: bold;
  /* top: 40% !important; */
  top: 66px !important;
  text-align: left;
  margin-bottom: 16px;
  margin-left: 13vw;
  max-width: 50vw;
}

.banner-user-elem .banner-user-text {
  top: 66px !important;
  text-align: left;
  margin-left: 13vw;
  max-width: 50vw;
}

.banner-user-elem .treeOneImage {
  position: absolute;
  top: calc((310px - 13vw)/2) !important;
  right: 13vw;
}

.banner-user-elem .ant-image {
  /* top: -67px !important; */
  /* top: calc((300px - 13vw)/2) !important; */
  /* float: right; */
  /* margin-right: 13vw; */
  /* width: 13vw;
  height: 13vw; */
  /* max-width: 300px; */

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-user-elem .btn_box {
  top: 33% !important;
  text-align: left;
  margin-left: 13vw;
}

.banner_btn_other {
  background-color: #00B3FF !important;
}


.bannerItemBtnColor {
  background: #00B3FF !important;
}


.banner-anim-elem .bg {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;

  -moz-background-size: 100% 100% !important;
  background-size: 100% 100% !important;
}

.banner-anim-arrow-default {
  position: absolute !important;
  width: 20px !important;
  height: 60px !important;
  margin-top: -30px !important;
  top: 50% !important;
  background: rgba(0, 0, 0, 0.3) !important;
  display: none !important;
}

.banner-user:hover .banner-anim-arrow-default {
  position: absolute !important;
  width: 20px !important;
  height: 60px !important;
  margin-top: -30px !important;
  top: 50% !important;
  background: rgba(0, 0, 0, 0.3) !important;
  display: block !important;
}

/* ----------------- banner--------------------- */

/* ----------------- bannerItem--------------------- */





.bannerItemText {
  color: #FFFFFF;
  top: 66px !important;
  text-align: left;
  /* white-space: pre-wrap; ===>让div 解析换行\n为换行 */
  white-space: pre-wrap;
}



/* ----------------- bannerItem--------------------- */











































/***********************滴滴行程单start*******************************/
.didiBack {
  background: #525659 !important;
  width: 100vw !important;
  height: auto !important;
  min-height: calc(100vh + 120px) !important;
  padding: 60px 0 !important;
  position: relative !important;
}

.didiPage {
  background: #ffffff !important;
  width: 52vw !important;
  min-width: 900px !important;
  height: auto !important;
  /* min-height: 100vh !important; */
  min-height: 841px !important;
  margin: 16px auto !important;
  padding-bottom: 280px !important;
}

.didiPageBox {
  margin: 0 68px !important;
}

.didiNameText {
  display: inline !important;
  float: right !important;
  width: calc(100% - 55px) !important;
  height: 100% !important;
  text-align: center !important;
  border: none !important;
  border-bottom: #dadada solid 1px !important;
}

.didiNameBox {
  margin-top: 15px !important;
  margin-bottom: 35px !important;
  font-size: 14px !important;
  padding: 0 21px !important;
}

.didiTimeBox {
  font-size: 14px !important;
  font-weight: 600 !important;
  position: relative !important;
  margin-bottom: 10px !important;
}

.didiTimefirst {
  display: inline !important;
}

.didiTimeSecond {
  display: inline !important;
  position: absolute !important;
  left: 256px !important;
}

.didiBorder {
  margin-right: 3px !important;
  margin-top: -2px !important;
}

.didicell {
  border: none !important;
  padding: 15px 7px !important;
  overflow: hidden !important;
  max-width: 200px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff !important;
}

.didiHead {
  background-color: #666666 !important;
  color: #d6d9e0 !important;
  padding: 7px 7px !important;
}

.didiCellColor {
  background-color: #f0f0f0 !important;
}

.diditableBorder {
  border: 1.5px #d4d4d4 solid !important;
  margin-bottom: 300px !important;
}

.didiImg {
  width: 30px !important;
  height: 30px !important;
}

.printBtn {
  position: absolute !important;
  width: auto !important;
  height: auto !important;
  z-index: 999 !important;
  right: 0 !important;
  margin: 20px !important;
}

.baseDiv {
  height: 100vh !important;
  width: 100vw !important;
}

.didiPageDiv {
  background: #ffffff !important;
  width: 50vw !important;
  min-width: 900px !important;
  height: auto !important;
  min-height: 300px !important;
  margin: 16px auto !important;
  padding-bottom: 280px !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.didiPageTitle {
  text-align: center !important;
  font-size: 30px !important;
  margin: 20px 0 !important;
}

.didiPageItem {
  margin: 20px 20px !important;
}


.didiInputIcon {
  margin-left: -30px !important;
}

.didiPageBtn {
  width: 150px !important;
}

.container {
  display: flex !important;
  flex-wrap: wrap !important;
}

.textField {
  margin-left: auto !important;
  width: 200px !important;
}

.homepageMoveLogo {
  z-index: 2 !important;
  color: #FFF !important;
  text-align: center !important;
  width: 100% !important;
}

.blueBtnSmall {
  --antd-wave-shadow-color: #1890ff !important;
  --scroll-bar: 0 !important;
  -webkit-text-size-adjust: 100% !important;
  --blue: #007bff !important;
  --indigo: #6610f2 !important;
  --purple: #6f42c1 !important;
  --pink: #e83e8c !important;
  --red: #dc3545 !important;
  --orange: #fd7e14 !important;
  --yellow: #ffc107 !important;
  --green: #28a745 !important;
  --teal: #20c997 !important;
  --cyan: #17a2b8 !important;
  --white: #fff !important;
  --gray: #6c757d !important;
  --gray-dark: #343a40 !important;
  --primary: #007bff !important;
  --secondary: #6c757d !important;
  --success: #28a745 !important;
  --info: #17a2b8 !important;
  --warning: #ffc107 !important;
  --danger: #dc3545 !important;
  --light: #f8f9fa !important;
  --dark: #343a40 !important;
  --breakpoint-xs: 0 !important;
  --breakpoint-sm: 576px !important;
  --breakpoint-md: 768px !important;
  --breakpoint-lg: 992px !important;
  --breakpoint-xl: 1200px !important;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-feature-settings: "tnum", "tnum" !important;
  -webkit-font-smoothing: antialiased !important;
  touch-action: manipulation !important;
  overflow: visible !important;
  float: right !important;
  box-shadow: 0 3px 5px 2px rgba(33, 203, 243, .3) !important;
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%) !important;
  color: #fff !important;
  height: 30px !important;
  margin: 0 8px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  outline: none !important;
  border: 0 !important;
  display: inline-flex !important;
  position: relative !important;
  align-items: center !important;
  user-select: none !important;
  vertical-align: middle !important;
  justify-content: center !important;
  text-decoration: none !important;
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  min-width: 64px !important;
  box-sizing: border-box !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase !important;
  padding: 6px 8px !important;
  -webkit-appearance: button !important;
  cursor: pointer !important;
}

.toolsBox {
  width: 350px !important;
  padding: 30px !important;
  height: auto !important;
  justify-content: center !important;
  text-align: center !important;
  background: #FFF !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -175px !important;
  margin-top: -175px !important;
  border-radius: 8px !important;
}

.registerTitle {
  font-size: 30px !important;
  margin-bottom: 30px !important;
}

.registerInput {
  margin: 10px 0 !important;
}

.registerCode {
  cursor: pointer !important;
  position: absolute !important;
  display: flex !important;
  right: 0px !important;
  margin: -27px 38px 0 0px !important;
}

.showAllDiv {
  display: flow-root !important;
  bottom: 0 !important;
  position: absolute !important;
  right: 0 !important;
}


.floatRight {
  float: right !important;
}

.floatLeft {
  float: left !important;
}

.blueBtn {
  color: white !important;
  border: 0 !important;
  height: 50px !important;
  margin: 0 8px !important;
  padding: 0 90px !important;
  font-size: 21px !important;
  border-radius: 3px !important;
  box-shadow: rgba(33, 203, 243, 0.3) 0px 3px 5px 2px !important;
  background: linear-gradient(45deg, rgb(33, 150, 243) 30%, rgb(33, 203, 243) 90%) !important;
}

.blueBtnSmall {
  color: white !important;
  border: 0 !important;
  padding: 0px 13px !important;
  height: 30px !important;
  margin: 0 8px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  border-radius: 3px !important;
  outline: none !important;
  box-shadow: rgba(33, 203, 243, 0.3) 0px 3px 5px 2px !important;
  background: linear-gradient(45deg, rgb(33, 150, 243) 30%, rgb(33, 203, 243) 90%) !important;
}

.spanText {
  white-space: pre-line !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 5 !important;
  /*显示几行*/
  overflow: hidden !important;
  max-height: 290px !important;
  overflow-y: hidden !important;
}

.textareaDiv {}

.modalText {
  margin: 0px 30px 20px 30px !important;
}

.textarea {
  width: 100% !important;
  font-size: 18px !important;
  min-width: 600px !important;
  min-height: 300px !important;
  max-height: 600px !important;
  overflow: auto !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .1) !important;
}

.textarea:hover {
  /*border: 1px #ddd solid !important;*/
}

.textarea:focus {
  outline: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .1) !important;
}



.ocrDiv {
  margin: 30px 100px !important;
}

.ocrImg {
  object-fit: contain !important;
}

#contained-button-imgs {
  display: none !important;
}

#textareaData {
  min-width: 60vw !important;
  padding: 20px !important;
}


.adDiv {
  /* height: 45vh !important; */
  height: 500px !important;
  max-height: 768px !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  background-color: #36275d !important;
}

.adDiv::after {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  content: "" !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
  background-color: rgba(28, 206, 234, 0.82) !important;
  background: linear-gradient(-45deg, rgba(147, 26, 222, 0.83) 0%, rgba(28, 206, 234, 0.82) 100%) !important;
}

#containerId {
  padding: 64px 0px 64px 0px !important;
  margin: 0px !important;
}

.textOcrTitle {
  margin-left: 15px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  line-height: 64px !important;
  color: #FFF;
  /* background-image: -webkit-linear-gradient(right, #205edb, #00aeee, #205edb) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important; */
  min-width: 100px !important;
  /* display: block !important; */
}

.textOcrTitlePhone {
  margin-left: 10px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 64px !important;
  /* background-image: -webkit-linear-gradient(right, #205edb, #00aeee, #205edb) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important; */
  min-width: 100px !important;
  /* display: block !important; */
}

.header_logo {
  margin-top: -10px;
}

.fixed-top {
  right: 0 !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 1030 !important;
}

.navbar-transparent {
  background: transparent !important;
  box-shadow: none !important;
  color: #fff !important;
  padding-top: 20px !important;
}

.supportTitle {
  border-bottom: 0 !important;
  color: #000 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 27px !important;
  margin: 40px 0 15px !important;
  padding-bottom: 0 !important;
  padding-left: 12px !important;
  position: relative !important;
}

.supportDivider {
  margin-top: 20px !important;
}

/***********************滴滴行程单end*******************************/


/***********************本地视频播放start*******************************/


.mainBox {
  height: 100vh !important;
  width: 100vw !important;
  /*   vh相对单位，相对于浏览器的尺寸改变*/
  /* background: #4c4c4c url('/img/background.jpeg') !important; */
  background-size: cover !important;
  /*以容器为基准,将容器覆盖满,哪怕图片失真、模糊*/
  /*margin: auto !important;/* margin:auto和margin:0 auto    但不知道父容器宽高，不合适*/
  /*将容器设置为弹性容器*/
  display: inline-block !important;
  justify-content: center !important;
  /*在X方向居中*/
  align-items: center !important;
  /*在Y方向居中*/
  /*课外拓展：将盒子在垂直方向居中的所有方式*/
}

#wrapper {
  width: 850px !important;
  display: flex !important;
}

.speed {
  flex: 1 !important;
  /*等比继承,本来应该是1:1继承，但是vedio有固定宽度，所以speed拿剩下宽度*/
  margin: 10px !important;
  background-color: #fff !important;
  border-radius: 50px !important;
  display: flex !important;
  overflow: hidden !important;
  /*起到：规定子容器不能超出，能保持父类圆角效果*/
  align-items: flex-start !important;
  text-align: center !important;
  max-width: 51px !important;
  /* */

}

.speed-bar {
  width: 100% !important;
  height: 16.3% !important;
  background: linear-gradient(-170deg, #2376ae 0%, #c16ecf 100%) !important;
  /*设置渐变色样式*/
  display: flex !important;
  /*使得容器可以设置接下来两个样式*/
  justify-content: center !important;
  align-items: center !important;
  color: #fff !important;
  cursor: pointer !important;
}

.videoBox {
  margin-top: calc(50vh - 215px) !important;
  margin-left: calc(50% - 425px) !important;
}

.fileinput-button {
  position: relative !important;
  display: inline-block !important;
  overflow: hidden !important;
}

.fileinput-button input {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  opacity: 0 !important;
  -ms-filter: 'alpha(opacity=0)' !important;
  font-size: 200px !important;
}

.videoNameBox {
  color: #fff !important;
  font-size: 26px !important;
  margin-bottom: 16px !important;
  width: 850px !important;
}

#videoName {
  margin-left: 20px !important;
}

.inputfile {
  cursor: pointer !important;
}

.selectStyle {
  text-align: center !important;
  border: none !important;
  height: 26px !important;
  margin-left: 10px !important;
  margin-top: 396px !important;
  cursor: pointer !important;
}

.selectvideoBtn {
  cursor: pointer !important;
}

/***********************本地视频播放end*******************************/

.site-layout-content {
  min-height: 100vh !important;
  /* padding-bottom: 35px !important; */
  background: #F5F5F5;
  /* min-height: 780px !important; */
  /* padding: 24px !important; */
}

main {
  background: #fff !important;
  padding: 0px !important;
}




/* test */
.code-box-shape {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 4px !important;
  background: #1890ff !important;
}