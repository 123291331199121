/* 公共的样式 start*/

.positionRelative {
    position: relative;
}

.displayInlineBlock {
    display: inline-block;
}

.displayFlex {
    display: flex;
}

.textAlignCenter {
    text-align: center;
}

.otherTextCenter {
    /* 图片和文字在一行，文字居中 在img中补上一个vertical-align:middle;就可以了 */
    vertical-align: middle;
}

.textAlignRight {
    text-align: right;
}

.flexCenter {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.cursorPointer {
    cursor: pointer;
}

.backgroundWhite {
    background: #FFFFFF !important;
}

.minHeight100vh {
    min-height: 100vh;
}

.mainColor{
    color:#0766F4 !important;
}

.colorWhite {
    color: #FFFFFF !important;
}

.colorBlack{
    color: #222222 !important;
}

.colorRed {
    color: #EF1919 !important;
}

.fontWeightBold{
    font-weight: bold;
}




.textDecoration {
    text-decoration: underline !important;
}

.direction_rtl {
    direction: rtl;
}

.show {
    display: block;
}

.noshow {
    display: none;
}

/* loading加载中 start */
.loadingCircleBox {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
    animation: loadingCircle 1.5s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
}

@keyframes loadingCircle {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.loadingBox {
    opacity: 1;
    height: 60px;
    width: 60px;
    z-index: 999;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out 0;
    transition: all 0.3s ease-in-out 0;
    text-align: center;
    animation: 0.3s ease 0s 1 normal none running showSweetAlert;

    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
}


@keyframes stretchdelay {

    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1)
    }
}

.loadingBox div {
    height: 100%;
    width: 7px;
    margin: 0 3px 0 0;
    background-color: #fff;
    display: inline-block;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
    background: -webkit-linear-gradient(bottom, #00eefe, #007bff, #00eefe);
}

.loadingBox .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.loadingBox .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.loadingBox .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.loadingBox .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

/* loading加载中 end */



/* 公共的样式 end*******************************************************************************/